import React, { useState } from "react";
import { Card, CardBody } from "@nextui-org/react";
import "./services.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { services } from "./Data";

const Services = () => {
  const [cards] = useState(services);

  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 748,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      id="services"
      className="container relative service-c md:-mt-24 sm:-mt-34"
    >
      <div className="service">
        <div className="py-2 text-center">
          <span className="text-yellow-500 uppercase">Services</span>
          <h1 className="text-4xl font-semibold">What We Do</h1>
        </div>
      </div>
      <br></br>
      <Slider {...settings}>
        {cards.map((card) => (
          <>
            <Card key={card.title} className="shadow-md mx-2">
              <CardBody>
                <img
                  alt={card.title}
                  className="object-cover h-60 w-full rounded-lg"
                  src={card.image}
                />

                <div className="py-2 px-1">
                  <p className="py-2 text-large text-center uppercase font-semibold text-black-800">
                    {card.title}
                  </p>
                  <small className="text-default-600 text-center">
                    {card.text}
                  </small>
                </div>
              </CardBody>
            </Card>
            <span className="w-2"></span>
          </>
        ))}
      </Slider>
    </div>
  );
};

// Custom arrow components for navigation
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
};


export default Services;
